@import '../config';
@import '../mixins';

.spona-table__container {
    @include typography($typography-styles, highlight);
    position: relative;
    align-items: flex-start;
    width: 100%;
}

.spona-table {
    border-spacing: 0;
    border-collapse: separate;
    border: solid 1px #E3E3E3;
    border-radius: 10px;
}
.spona-table__head {
    padding: 12px 24px;
    background: #EBF8F5;
    font-size: 18px;
    font-weight: 600;
    color: #4D4A49;
    text-align: left;

    &:first-child {
        border-radius: 10px 0 0 0;
    }

    &:last-child {
        border-radius: 0 10px 0 0;
    }
}

.spona-table__body-cell {
    border: none;
    padding: 12px 24px;
    background-color: #FAFFFE;
    font-weight: 600;
    font-size: 14px;
    color: #8D8D8D;
    text-align: left;
    border-top: 1px solid #E3E3E3;
}

.spona-table__body-row {
    &:last-child td:first-child {
        border-radius: 0 0 0 10px;
    }

    &:last-child td:last-child {
        border-radius: 0 0 10px 0;
    }

    &:nth-child(even) td {
        background-color: #F0F5F4;
    }
}

.red {
    color: #E10000;
}

.green {
    color: #33BD9C;
}