@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@500');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@600');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@700');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@800');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@900');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700');

body {
  font-family: 'Raleway', serif;
  min-height: 100vh;
  background-color: #FCFCFC;
}

@tailwind base;
@tailwind components;
@tailwind utilities;