.react-chatbot-kit-chat-header {
    background: #33bd9c;
    color: #ffffff;
    font-family: 'Raleway', sans-serif;
    font-size: 16px;
}

.react-chatbot-kit-chat-container {
    width: 350px;
    border-radius: 10px;
    color: #1A1818;
}

.react-chatbot-kit-chat-bot-message {
    width: 250px;
    white-space: pre-line;
}

.react-chatbot-kit-chat-inner-container {
    background: #ffffff;
}

.react-chatbot-kit-chat-bot-avatar-container {
    background: #E3E3E3;
}

.react-chatbot-kit-user-avatar-container {
    background: #F90;
}

.react-chatbot-kit-chat-bot-avatar-letter {
    color: #1A1818;
}

.react-chatbot-kit-chat-input:focus {
    outline: none;
}

@media only screen and (max-width: 1024px) {
    .react-chatbot-kit-chat-container {
        width: 100%;
        height: 100vh;
    }

    .react-chatbot-kit-chat-inner-container {
        height: 100%;
    }
    
    .react-chatbot-kit-chat-bot-message {
        width: 100%;
    }

    .react-chatbot-kit-chat-header {
        padding-top: 70px;
    }
}
