.react-datepicker__input-container input {
    font-family: "Open Sans";
    color: #333;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 8px 40px;
    width: 262px;
    border-radius: 8px;
    border: 1px solid #DDD;
    background: #FFF;
}

.react-datepicker__input-container svg {
    margin-top: 2px;
    margin-left: 8px;
}

.react-datepicker__input-container button {
    margin-right: 8px;
}

.react-datepicker__input-container input:focus {
    outline: none;
}

.react-datepicker__close-icon::after {
    background-color: transparent !important;
    content: url('../../assets/icons/clear-date.svg') !important;
    outline: none;
    padding-top: 4px;
}

.react-datepicker {
    border: 1px solid #DDD;
    border-radius: 8px;
    padding: 10px;
    color: #333333;
    font-family: "Open Sans";
}

.react-datepicker__header {
    background: #FFF;
    border: none;
}

.react-datepicker__day-names {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.react-datepicker__day {
    font-size: 13px;
}

.react-datepicker__triangle {
    display: none;
}

.react-datepicker__day--selected {
    background: #2F80ED;
}

.react-datepicker__day--outside-month {
    color: #AAAAAA;
}