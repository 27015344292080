.gradient-line {
    width: 64px;
    height: 64px;
    position: absolute;
    left: -3px;
    top: -3px;
    border-radius: 50%;
    mask: radial-gradient(
            farthest-side,
            transparent calc(100% - 5px),
            #FCFCFC calc(100% - 5px)
    );
    background: linear-gradient(
            35deg,
            #FCFCFC 0%,
            #33BD9C 100%,
            rgba(255, 255, 255, 0.01) 100%
    ) 0 0 / 40% 70% no-repeat;
}