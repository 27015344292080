@import './config';

@mixin typography($typography-styles, $style) {
    $typography-style: map-get($typography-styles, $style);

    @each $property-key, $property-value in $typography-style {
        #{$property-key}: $property-value;
    }
}

@mixin generate-spacing-classes($property, $breakpoint, $value) {
    .#{$property}-#{$breakpoint}-#{$value} {
        #{$property}-#{$breakpoint}: #{$value}px;
    }
}

$spacing-properties: ("margin", "padding");
$spacing-values: (2, 4, 8, 12, 16, 24);
$box-sides: ("top", "right", "bottom", "left");

@each $property in $spacing-properties {
    @each $breakpoint in $box-sides {
        @each $value in $spacing-values {
            @include generate-spacing-classes($property, $breakpoint, $value);
        }
    }
}


$grid-columns: map-get($grid, grid-columns);
$grid-column-gap: map-get($grid, grid-column-gap);
$grid-row-gap: map-get($grid, grid-row-gap);

@mixin grid-container {
    .grid {
        display: grid;
        grid-template-columns: repeat($grid-columns, 1fr);
        grid-column-gap: $grid-column-gap;
        grid-row-gap: $grid-row-gap;
    }

    @for $i from 1 through $grid-columns {
        .column--span-#{$i} {
            grid-column: span #{$i};
        }
    }
}