@import url('https://fonts.googleapis.com/css2?family=Raleway');
@import './functions';

$base-colors: () !default;

$base-colors:  (
    primary: #33BD9C,
    dark-primary: #28957B,
    success: red,
    spona-white: #FFF,
    background: #FCFCFC,
    // text colors
    text-default: #1A1818,
    text-contrast: #FCFCFC,
    text-highlight: #33BD9C,
    text-caption: #4D4A49,
    // icon colors
    icon-default: #33BD9C,
);

$sizes: (
    spona-0: 0px,
    spona-2: 2px,
    spona-4: 4px,
    spona-8: 8px,
    spona-16: 16px,
    spona-20: 20px,
    spona-24: 24px,
    spong-32: 32px,
    spona-44: 44px,
    spona-128: 128px,
    spona-176: 176px,
    spona-153: 153px,
);

$typography: (
    font-family: 'Raleway',
    font-style: normal,
    font-weight: normal,
    font-size: 18px,
    line-height: normal,
    // font-weights
    light-weight: 400,
    normal-weight: 500,
    medium-weight: 600,
    semi-bold-weight: 800,
    bold-weight: 900,
    // font-sizes
    font-size-64: 64px,
    font-size-40: 40px,
    font-size-32: 32px,
    font-size-24: 24px,
    font-size-18: 18px,
    font-size-16: 16px,
    font-size-14: 14px,
    font-size-12: 12px,
    // text colors
    text-color: map-get($base-colors, text-default),
    text-color-primary: map-get($base-colors, primary),
);

$typography-styles: (
    h1: (
        font-family: map-get($typography, font-family),
        font-style: normal,
        font-weight: map-get($typography, bold-weight),
        font-size: map-get($typography, font-size-64),
        line-height: 144%,
        color: map-get($typography, text-color),
    ),
    h2: (
        font-family: map-get($typography, font-family),
        font-style: normal,
        font-weight: map-get($typography, semi-bold-weight),
        font-size: map-get($typography, font-size-32),
        color: map-get($typography, text-color),
    ),
    body: (
        font-family: map-get($typography, font-family),
        font-style: normal,
        font-weight: map-get($typography, light-weight),
        font-size: map-get($typography, font-size-18),
        line-height: normal,
        color: map-get($typography, text-color),
    ),
    highlight: (
        font-family: map-get($typography, font-family),
        font-style: normal,
        font-weight: map-get($typography, medium-weight),
        font-size: map-get($typography, font-size-18),
        line-height: normal,
        color: map-get($base-colors, text-default),
    ),
    caption: (
        color: map-get($base-colors, text-caption),
        font-family: Raleway,
        font-size: map-get($typography, font-size-14),
        font-weight: map-get($typography, medium-weight),
    ),
);

$breakpoints: () !default;

$breakpoints: map-extend((
    base: 320px,
    small: 960px,
    large: 1280px,
    x-large: 1920px,
), $breakpoints);

$grid: () !default;

$grid: map-extend((
    grid-columns: 12,
    grid-column-gap: 24px,
    grid-row-gap: 24px,
), $grid);